import React from 'react'
import {graphql} from 'gatsby'
import {Helmet} from 'react-helmet'

import {Content} from '../components/content'
import {Layout} from '../components/grid'
import {Header} from '../components/header'


interface PageData{
  about: {
    title: string
    body: string
  }
}

const AnswerTemplate: React.FC<{data: PageData}> = ({data}) => {
  const {about} = data

  return <>
    <Helmet title="About Ed-IT Solutions Self Help" />
    <Header />
    <Layout>
      <h1>{about.title}</h1>
      <Content html={about.body} />
    </Layout>
  </>
}

export default AnswerTemplate

export const PageQuery = graphql`
  query AboutPageQuery{
    about: directusAbout {
      title
      body
    }
  }
`